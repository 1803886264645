<template>
  <div class="middle">
    <div class="title">
      <img src="../../assets/image/qiye.png" alt="" />
    </div>
    <div class="content">
      <div class="content-desc">
        <span class="headline">{{ shop.name }}</span>
        <span class="desc-main" v-html="shop.content"></span>
      </div>
      <div class="content-image">
        <vue3VideoPlay
          v-if="isPlay"
          v-bind="options"
          :poster="shop.image"
          @pause="onPause"
          :volume="0.9"
          @ended="handleEnded"
        />
        <img v-if="!isPlay" :src="shop.image" alt="" />
        <img
          @click="playVideo"
          class="play-btn"
          v-if="shop.shop_video && !isPlay"
          src="../../assets/image/bofang.png"
          alt=""
        />
      </div>
    </div>
    <img class="vr-btn" src="../../assets/image/vrcaifangyuan.png" alt="" />
    <!-- <div id="scenePlayDom" :style="{ top }"></div> -->
  </div>
</template>
<script>
import { defineComponent, reactive, ref, onMounted } from "vue";

export default defineComponent({
  props: {
    shop: Object,
  },
  setup(props) {
    const isPlay = ref(false);
    const options = reactive({
      width: "100%", //播放器高度
      height: "100%", //播放器高度
      src: props.shop.shop_video, //视频源
      muted: false, //静音
      webFullScreen: false,
      autoPlay: true, //自动播放
      loop: false, //循环播放
      mirror: false, //镜像画面
      ligthOff: false, //关灯模式
      volume: 0.3, //默认音量大小
      control: true, //是否显示控制
      controlBtns: [
        "audioTrack",
        "quality",
        "speedRate",
        "volume",
        "setting",
        "pip",
        "fullScreen",
      ], 
    });
    const playVideo = (e) => {
      isPlay.value = true;
    };
    const onPause = (ev) => {
      isPlay.value = false;
    };
    const handleEnded = (e) => {
      isPlay.value = false;
    };

    onMounted(() => {
      // options.src = props.shop.shop_video
      // console.log('props.shop.shop_video :>> ', props.shop);
      // console.log(111111111111111111111111111111111111111111111111111111111111111111111111111111111111);
      // console.log("options :>> ", options.src);
    });
    return {
      handleEnded,
      playVideo,
      onPause,
      options,
      isPlay,
    };
  },
  data() {
    return {
      isPlay: false,
      top: 0,
    };
  },
});
</script>
<style lang="less" scoped>
.middle {
  margin: 40px auto;
  width: 100%;
  max-width: 1240px;
  .vr-btn {
    display: block;
    margin: 50px auto;
    padding-bottom: 20px;
  }
  .content {
    margin: 0 auto;
    width: 94.3%;
    transform: translateX(-3%);
    height: 508px;

    //   min-height: 450px;
    //   max-height: 571px;
    display: flex;
    justify-content: center;
    background: url(../../assets/image/jianjieBG.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    img {
      width: 65%;
    }
    &-image {
      width: 50%;
      height: 75%;
      position: absolute;
      top: 50%;
      right: -5%;
      transform: translate(0, -50%);
      img {
        width: 100%;
        height: 100%;
      }
      .play-btn {
        cursor: pointer;
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &-desc {
      top: 50%;
      left: 5%;
      width: 45%;
      min-height: 85%;
      transform: translateY(-50%);
      position: absolute;
      color: #ebeceb;
      opacity: 0.9;

      .desc-main {
        display: block;
        line-height: 26px;
        font-size: 16px;
        max-height: 350px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 13;
        &:nth-child(3) {
          margin-top: 20px;
        }
      }

      .headline {
        font-size: 28px;
        display: block;
        position: relative;
        margin-bottom: 40px;
        &::after {
          width: 30px;
          content: "";
          position: absolute;
          bottom: -20px;
          left: 0;
          border-bottom: 3px solid #52b470;
        }
      }
    }
  }
  .title {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    margin: 50px 0;
  }
}
</style>
