<template>
  <div class="wrapper">
    <Header :headerData="headerData" :exhibitorPage="true" />
    <ProductDetails :shop="shop" />
    <GoodsList :product="product" :total="total" />
    <Footer />
  </div>
</template>
<script>
import { DownloadOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, reactive } from "vue";
import Header from "../../components/Header";
import ProductDetails from "../../components/ProductDetails";
import GoodsList from "../../components/GoodsList";
import Footer from "../../components/Footer";
import { useRoute } from "vue-router";
import { get_merchant_details, IMAGE_URL } from "@/api";

export default defineComponent({
  components: {
    Header,
    Footer,
    GoodsList,
    ProductDetails,
    DownloadOutlined,
  },
  data() {
    return {
      product: [],
      shop: {},
      headerData: {},
      total: 0,
    };
  },
  mounted(option) {
    this.merchantDetailsFn(this.$route.query.id);
  },
  methods: {
    async merchantDetailsFn(id) {
      const {
        data: {
          data: { product, shop },
        },
      } = await get_merchant_details({ id, page: 1, limit: 8 });
      this.product = product.data.map((item) => ({
        ...item,
        image: IMAGE_URL + item.image,
        images: IMAGE_URL + item.images,
        shop_id: id,
      }));
      this.total = product.total;
      shop.content = shop.content.replace(/color:#202124/, "");
      this.shop = shop;
      this.shop.image = IMAGE_URL + shop.image;
      this.shop.images = IMAGE_URL + shop.images;
      this.shop.logo = IMAGE_URL + shop.logo;
      this.shop.yyzz_images = IMAGE_URL + shop.yyzz_images;
      if(this.shop.shop_video) this.shop.shop_video = IMAGE_URL + shop.shop_video;
      this.headerData = {
        name: shop.name,
        number: shop.shop_number,
        shop_id: id,
      };
      this.headerData = JSON.parse(JSON.stringify(this.headerData));
    },
  },

  setup() {
    const route = useRoute();
    return {
      current: ref(6),
    };
  },
});
</script>

<style lang="less" scoped>
.wrapper {
  min-height: 100vw;
  min-width: 1600px;
  background: #f1f6f4;

  .home-btn {
    margin-top: 50px;
    width: 100%;
    height: 150px;
    background: url(../../assets/image/backhomeBG.jpg) no-repeat;
    background-size: 100% 100%;
    padding: 0 16.95%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img {
      width: 150px;
      // height: 80px;
    }
  }
}
</style>
